import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDownIcon } from 'lucide-react';
import { FaRoad } from "react-icons/fa6";
import { RiUserLocationFill, RiCameraAiLine } from "react-icons/ri";
import { IoTrainOutline } from "react-icons/io5";
import { PiAirplaneTiltBold } from "react-icons/pi";
import line from '../../line/line.svg';

function RouteMap() {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const locations = [
    {
      id: 1,
      name: "Near By Attractions",
      icon: <RiCameraAiLine />,
      details: [
        { text: "Chail Wildlife Sanctuary", distance: "63KM" },
        { text: "Kali Ka Tibba", distance: "7KM" },
        { text: "Sidh Baba ka Mandir", distance: "5KM" },
        { text: "Kufri", distance: "30KM" },
        { text: "Chail Cricket Ground", distance: "2KM" },
        { text: "Sadhupul", distance: "19KM" },
        { text: "Junga Palace", distance: "20KM" },
        { text: "Stone Kumbh Shiva Temple", distance: "8KM" },
      ]
    },
    {
      id: 2,
      name: "Key Distances",
      icon: <RiUserLocationFill />,
      details: [
        { text: "Shimla", distance: "46KM" },
        { text: "Chandigarh", distance: "106KM" },
        { text: "Dehradun", distance: "218KM" },
        { text: "Punjab", distance: "283KM" },
        { text: "Amritsar", distance: "329KM" },
        { text: "Delhi", distance: "337KM" },
      ]
    },
    {
      id: 3,
      name: "Highways",
      icon: <FaRoad />,
      details: [
        { text: "Shimla (HP SH 1)", distance: "28KM" },
        { text: "Sirmour Solan (HP SH 2)", distance: "78KM" },
        { text: "Hamirpur Kangra (HP SH 39)", distance: "59sKM" },
      ]
    },
    {
      id: 4,
      name: "Railways & Metros",
      icon: <IoTrainOutline />,
      details: [
        { text: "Shimla Railway Station", distance: "43KM" },
      ]
    },
    {
      id: 5,
      name: "Airports",
      icon: <PiAirplaneTiltBold />,
      details: [
        { text: "Simla (SLV)", distance: "60KM" },
        { text: "Chandigarh Airport", distance: "117KM" },
      ]
    },
  ];

  return (
    <section className="relative min-h-screen overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#80702F]/5 to-transparent 
        opacity-50" />
      <div className="container mx-auto px-4 md:px-6 relative z-10">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center max-w-4xl mx-auto mb-12 md:mb-5 flex flex-col items-center" 
        >
          <h2 className="text-4xl md:text-5xl font-bold yeseva text-[#ad9b73]">
            Discover Chail
          </h2>

          <img src={line} alt="line" className="w-full h-[50px] mt-[-2px] opacity-70 ml-[-5px] " /> 

        </motion.div>

        <div className="flex flex-col-reverse lg:flex-row  items-center lg:items-start">

          <div className="space-y-8 w-[400px] lg:mt-0 mt-8">
            {locations.map((location) => (
              <AccordionCard
                key={location.id}
                location={location}
                isSelected={selectedLocation?.id === location.id}
                onSelect={() => setSelectedLocation(location.id === selectedLocation?.id ? null : location)}
              />
            ))}
          </div>

            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              className="w-full"
            >
                <img 
                  src="https://d2r3u9c7j9d9om.cloudfront.net/chailroutemap.png"
                  alt="Location Map"
                  className=""
                />
            </motion.div>

        </div>
      </div>

      <style jsx global>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
        }

        .custom-scrollbar::-webkit-scrollbar-track {
          background: rgba(128, 112, 47, 0.1);
          border-radius: 10px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: rgba(128, 112, 47, 0.2);
          border-radius: 10px;
        }

        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: rgba(128, 112, 47, 0.3);
        }
      `}</style>
    </section>
  );
}

function AccordionCard({ location, isSelected, onSelect }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      className="group relative w-[400px] "
    >
      <div
        className={`p-6 cursor-pointer transition-all duration-500
          before:absolute before:inset-0 before:bg-gradient-to-r 
          before:from-transparent before:via-[#ad9b73]/5 before:to-transparent
          before:opacity-0 hover:before:opacity-100 before:transition-opacity
          ${isSelected ? 'before:opacity-100' : ''}`}
        onClick={onSelect}
      >
        <div className="flex items-center gap-4 justify-between relative">
          <div className="flex items-center gap-6">
            <motion.div
              whileHover={{ scale: 1.1 }}
              className="relative"
            >
              <div className="absolute inset-0 bg-[#ad9b73] blur-lg opacity-20" />
              <div className="relative text-[#ad9b73] text-3xl">
                {location.icon}
              </div>
            </motion.div>
            <h3 className="text-xl  yeseva text-[#ad9b73] group-hover:translate-x-2 
              transition-transform duration-300 relative">
              {location.name}
              <div className="absolute -bottom-2 left-0 w-0 group-hover:w-full h-0.5 
                bg-gradient-to-r from-[#ad9b73]/50 to-transparent transition-all duration-300" />
            </h3>
          </div>
          <ChevronDownIcon
            className={`w-6 h-6 transition-all duration-500 text-[#ad9b73] 
              ${isSelected ? 'rotate-180' : 'group-hover:translate-y-1'}`}
          />
        </div>
      </div>

      <AnimatePresence>
        {isSelected && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="overflow-hidden"
          >
            <div className="px-4 py-6">
              <div className="grid gap-8">
                {location.details.map((detail, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="group/item relative"
                  >
                    <div className="relative flex items-center justify-between text-[#80702F] gap-3
                      before:absolute before:inset-0 before:-skew-x-6 before:bg-gradient-to-r 
                      before:from-white/5 before:to-transparent before:opacity-0 
                      hover:before:opacity-100 before:transition-opacity duration-300"
                    >
                      <span className="text-base md:text-lg relative">
                        {detail.text}
                        <div className="absolute -bottom-1 left-0 w-0 group-hover/item:w-full h-px 
                          bg-gradient-to-r from-[#80702F]/30 to-transparent transition-all duration-300" />
                      </span>
                      <span className="text-sm font-medium relative px-4 py-1
                        before:absolute before:inset-0 before:bg-gradient-to-r 
                        before:from-[#ad9b73]/10 before:to-transparent before:opacity-0
                        group-hover/item:before:opacity-100 before:transition-opacity"
                      >
                        {detail.distance}
                      </span>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default RouteMap;