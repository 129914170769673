import React from 'react'
import Header from '../components/home/Header'
import ProjectHighlights from '../components/home/ProjectHighlights'
// import WhyChail from '../components/home/WhyChail'
import Amenites from './Amenites'
import RouteMap from '../components/home/RouteMap'
import Announcement from '../components/home/Announcement'
import BloomingProject from '../components/home/BloomingProject'
import { Helmet } from 'react-helmet'


function Home() {

  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Cottages And Villas In Chail Himachal- Chail Heights Villas</title>
        <meta
          name="description"
          content="Chail Heights Villas has arrived along with the lovely villas and cottages in Himachal which can be your ideal home or property in Chail, Himachal Pradesh"
        />
      </Helmet>
      <Header />
      <Announcement />
      <BloomingProject />
      <ProjectHighlights />
      <div className='mt-10 md:mt-20' />
      <Amenites />
      <div className='mt-0 md:mt-20' />
      <RouteMap />

    </div>
  )
}

export default Home