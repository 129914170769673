import { motion } from 'framer-motion'
import React from 'react'
import line from "../line/line.svg"
import BlogCard from "../components/blogs/BlogCard"
import blogbanner1 from "../components/blogs/bannerchail.jpg"

function Blogs() {
     const blogPosts = [
        {
          title: "Chail: Best place to invest in Himachal Pradesh",
          alt:"Best Place for property investment in Chail",
          imageUrl: blogbanner1,
          href:"/blog/chail-best-place-to-invest-in-himachal-pradesh",
          date:"Mar 25, 2025"
        },
      ];
    return (
        <div className='min-h-screen pt-[150px] yeseva'>
            <div className="max-w-7xl  mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, margin: "-100px" }}
                    transition={{ duration: 0.8 }}
                    className="text-center mb-8 flex flex-col items-center justify-center"
                >
                    <h2 className="text-4xl md:text-5xl font-bold text-[#ad9b73] font-serif">
                        Blogs
                    </h2>
                    <img src={line} alt="line" className="w-full h-[50px] opacity-70 ml-[-5px]" />
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, margin: "-100px" }}
                    transition={{ duration: 0.8 }}
                    className="grid grid-cols-1 gap-5 md:grid-cols-3"
                >
                    <BlogCard blogPosts={blogPosts}/>
                </motion.div>
            </div>
        </div>
    )
}

export default Blogs
