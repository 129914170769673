import React from 'react';
import { ArrowRight, CalendarRange } from 'lucide-react';

const BlogCard = ({ blogPosts }) => {


    return (
        <>
            {blogPosts.map((post, index) => (
                <a
                    href={post.href}
                    target='_blank'
                    rel='noreferrer'
                    key={index}
                >
                    <div
                        key={index}
                        className="group border-2 p-3 border-[#ad9b7339] rounded-lg grid gap-6 items-center"
                    >
                        {/* Image Section */}
                        <div className="overflow-hidden rounded-lg">
                            <img
                                src={post.imageUrl}
                                alt={post.alt}
                                className="w-full object-contain rounded-lg transform group-hover:scale-105 aspect-video transition-transform duration-300"
                            />
                        </div>

                        {/* Content Section */}
                        <div>
                            <h2 className="text-xl text-gray-800 mb-3 group-hover:text-[#98855e] transition-colors">
                                {post.title}
                            </h2>

                            <div className='flex gap-2 my-2 text-gray-700 group-hover:text-[#98855e] text-xs items-center'>
                                <CalendarRange size={16}/> {post.date}
                            </div>

                            <div
                                className="inline-flex text-sm items-center text-gray-700 group-hover:text-[#98855e] transition-colors group/link"
                            >
                                Read More
                                <ArrowRight
                                    size={20}
                                    className="ml-2 group-hover:translate-x-1 transition-transform"
                                />
                            </div>
                        </div>
                    </div>
                </a>
            ))}
        </>
    );
};

export default BlogCard;