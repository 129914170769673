import React from 'react';
import { motion } from 'framer-motion';
import line from "../../line/line.svg";
import { FaQuoteLeft } from "react-icons/fa";
import b1 from "../../line/b1.png"
import blogbanner from "./bannerchail.jpg"
import { Helmet } from 'react-helmet';


function Blog1() {

    return (
        <div className='container mx-auto min-h-screen pt-[150px] flex-col justify-center items-center'>
            {/* Helmet for SEO */}
            <Helmet>
                <title>Chail: Best place For Property Investment in Himachal</title>
                <meta
                    name="description"
                    content="Looking for the best place to invest in Himachal? Discover why Chail is the top location for property investment in Himachal Pradesh with its scenic beauty."
                />
            </Helmet>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "-100px" }}
                transition={{ duration: 0.8 }}
                className="max-w-6xl mx-auto p-5"
            >
                <img src={blogbanner} className='mb-2 md:mb-8 aspect-video object-contain' alt="Best Place for property investment in Chail" />
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "-100px" }}
                transition={{ duration: 0.8 }}
                className="text-center flex flex-col items-center justify-center"
            >
                <h1 className="text-3xl md:text-4xl font-bold text-[#ad9b73] font-serif">
                    Chail: Best place to invest in Himachal Pradesh
                </h1>
                <img src={line} alt="line" className="w-full h-[50px] opacity-70 ml-[-5px]" />
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "-100px" }}
                transition={{ duration: 0.8 }}
                className="max-w-7xl mx-auto"
            >
                <div className="relative p-8 md:p-12 before:absolute before:inset-0 before:bg-gradient-to-r 
            before:from-transparent before:via-[#ad9b73]/5 before:to-transparent
            before:opacity-0 before:transition-opacity rounded-lg"
                >
                    <div className="relative">
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            whileInView={{ opacity: 0.1, scale: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.6 }}
                            className="absolute -top-6 -left-4 text-[#ad9b73]"
                        >
                            <FaQuoteLeft className="w-12 h-12" />
                        </motion.div>

                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, margin: "-100px" }}
                            variants={{
                                visible: {
                                    transition: {
                                        staggerChildren: 0.2
                                    }
                                }
                            }}
                            className="space-y-6 text-gray-600 leading-relaxed text-md md:text-lg relative text-justify z-10"
                        >
                            <motion.p
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    visible: { opacity: 1, y: 0 }
                                }}
                                transition={{ duration: 0.6 }}
                            >
                                Chail is a hidden jam in Himachal Pradesh. Chail’s location is very prominent for all potential investors and home buyers who are looking to invest in Himachal Pradesh. One of the greatest advantages of Chail is its commitment to preserving nature, with almost 70% of the land area reserved as a green forest zone. This ensures that Chail will always maintain its pristine beauty, free from overcrowding and the chaos of city life.
                            </motion.p>
                            <motion.div
                                initial={{ opacity: 0, y: -20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, margin: "-100px" }}
                                transition={{ duration: 0.8 }}
                                className=""
                            >
                                <h1 className="text-xl md:text-2xl font-bold font-serif">
                                    Chail Overview:
                                </h1>
                            </motion.div>
                            <motion.p
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    visible: { opacity: 1, y: 0 }
                                }}
                                transition={{ duration: 0.6 }}
                            >
                                <span className='font-semibold underline'>Let’s talk about its location:</span>   It’s one hour away from Solan and one and a half hours away from Shimla, which is why this place is easily accessible for anyone who is traveling to Chail, and that’s why it makes the best place to invest in Himachal Pradesh.
                            </motion.p>
                            <motion.p
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    visible: { opacity: 1, y: 0 }
                                }}
                                transition={{ duration: 0.6 }}
                            >
                                <span className='font-semibold underline'>Amazing weather around the year:</span>  Chail is at a height of 7000 feet from sea level, which makes this place’s weather cool and pleasant around the year, and it also witnesses snowfall during winters. Places like Shimla, Solan, and Kasauli are at low altitudes, which is why they are hot in comparison to Chail, and what’s the point of owning a place on a Hill station when you have to turn on your air conditioner or fan?
                            </motion.p>
                            <motion.p
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    visible: { opacity: 1, y: 0 }
                                }}
                                transition={{ duration: 0.6 }}
                            >
                                Chail is a perfect destination to own a vacation home, and we are excited to introduce our latest project, which is <strong>Chail Heights Villas</strong>, nestled in the breathtaking Shivalik Ranges of Chail, Himachal Pradesh. The premium gated township in the heart of Chail spread across 8 acres of pristine landscape, has a private road connected to the main highway, which provides amazing accessibility to everyone. All the villas and apartments are accessible through a private project road. The project offers one <strong>1BHK apartment in two different sizes and 14 independent villas </strong> with all the modern amenities and necessities one could imagine. Designed to provide both comfort and recreation, the project features a modern clubhouse, a multi-cuisine restaurant, and a dedicated indoor games area for entertainment. Residents can unwind in the beautifully landscaped common park, perfect for leisurely walks and relaxation amidst nature.
                            </motion.p>
                            <motion.p
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    visible: { opacity: 1, y: 0 }
                                }}
                                transition={{ duration: 0.6 }}
                            >
                                Views from the project: The project offers breathtaking views of the majestic <strong>Shivalik Ranges</strong>, allowing residents to wake up to the serene beauty of the mountains every day.
                            </motion.p>
                            <motion.div
                                initial={{ opacity: 0, y: -20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true, margin: "-100px" }}
                                transition={{ duration: 0.8 }}
                                className="text-center my-8 flex flex-col items-center justify-center"
                            >
                                <h2 className="text-3xl md:text-4xl font-bold text-[#ad9b73] font-serif">
                                    Why invest in Chail?
                                </h2>
                                <img src={line} alt="line" className="w-full h-[50px] opacity-70 ml-[-5px]" />
                            </motion.div>
                            <motion.div
                                className='flex items-center gap-5'
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    visible: { opacity: 1, y: 0 }
                                }}
                                transition={{ duration: 0.6 }}
                            >
                                <img src={b1} className='h-[25px]' alt="" />
                                <p><span className='font-semibold'>Scenic Beauty & Peaceful Environment:</span>  Unlike the crowded tourist hubs of Shimla and Manali, Chail remains an untouched paradise. Surrounded by pine and deodar forests, it offers stunning landscapes, clean air, and a pollution-free environment.</p>
                            </motion.div>
                            <motion.div
                                className='flex items-center gap-5'
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    visible: { opacity: 1, y: 0 }
                                }}
                                transition={{ duration: 0.6 }}
                            >
                                <img src={b1} className='h-[25px]' alt="" />
                                <p><span className='font-semibold'>Growing Real Estate Market:</span>  The demand for holiday homes in Himachal Pradesh has been steadily rising. Chail, with its serene atmosphere and premium location, offers great potential for property appreciation.</p>
                            </motion.div>
                            <motion.div
                                className='flex items-center gap-5'
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    visible: { opacity: 1, y: 0 }
                                }}
                                transition={{ duration: 0.6 }}
                            >
                                <img src={b1} className='h-[25px]' alt="" />
                                <p><span className='font-semibold'>Tourism & Rental Income Potential:</span>  Chail attracts tourists throughout the year as a sought-after hill station. Investing in a vacation home here allows you to earn rental income while also having a private retreat whenever you desire.</p>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default Blog1
