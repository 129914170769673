import React from 'react'
import Header from '../components/home/Header'
import Announcement from '../components/home/Announcement'
import Amenities from './Amenites'
import RouteMap from '../components/home/RouteMap'
import Offerings from '../components/landing/Offerings'
import Gallery from '../components/landing/Gallery'
import NavbarLanding from '../components/NavbarLanding'


function Landing() {
  const images = [
    {
      id: 1,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/1.jpg',
      name: "Churdhar"
    },
    {
      id: 5,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/5.jpg',
      name: "Hassan Valley Green Forest"
    },
    {
      id: 6,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/6.jpg',
    },
    {
      id: 10,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/10.jpg',
    },
    {
      id: 11,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/11.jpg',
    },
    {
      id: 12,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/12.jpg',
    },
  ];

  return (
    <div className='min-h-screen'>
      <NavbarLanding />
      <Header />
      <div className='container mx-auto'>
        <Announcement />
        <div id='project' className='scroll-m-[150px]' ><Offerings /></div>
        <div id='amenties' className='scroll-m-[150px]' ><Amenities /></div>
        <div id='routemap' className='scroll-m-[150px]' ><RouteMap /></div>
        <div id='gallery' className='scroll-m-[150px]' ><Gallery imageslanding={images} /></div>
        <div className='mt-20'>
        </div>
      </div>
    </div>
  )
}

export default Landing