import { BrowserRouter , Route, Routes, useLocation } from 'react-router-dom';
import Home from './screens/Home';
import About from './screens/About';
import Gallery from './screens/Gallery';
import Amenities from './screens/Amenites';
import Contact from './screens/Reach';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Apartment from './components/offerings/Apartment';
import Duplexvilla from './components/offerings/Duplexvilla';
import Notfound from './screens/Notfound';
import Landing from './screens/Landing';
import Blogs from './screens/Blogs';
import Blog1 from './components/blogs/Blog1';

function AppContent() {
  const location = useLocation();
  const showHeaderFooter = location.pathname !== "/landing";  

  return (
    <main className="relative bg-[#fffcef] bg-gold-leaf min-h-screen">
      {/* Main content */}
      <div>
        {showHeaderFooter && <Navbar />}
        <div className="rounded-md flex flex-col fixed z-40 bottom-0 right-0 gap-5 m-5">
          <a
            href="https://www.google.com/maps/place/30%C2%B057'52.1%22N+77%C2%B013'37.8%22E/@30.9644585,77.2245903,731m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d30.9644585!4d77.2271652?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="hover:scale-125 transition-all duration-100 h-10 md:h-12 w-10 md:w-12"
              src="https://img.icons8.com/color/96/marker--v1.png"
              alt="location"
            />
          </a>
          <a href="tel:+918510850101">
            <img
              className="hover:scale-125 transition-all duration-100 h-10 md:h-12 w-10 md:w-12"
              src="https://www.theshubhamgroup.com/static/media/icon_call.092a33d874f46145879d.png"
              alt="phone"
            />
          </a>
          <a href="https://wa.me/918510850101" target="_blank" rel="noreferrer">
            <img
              className="hover:scale-125 transition-all duration-100 h-10 md:h-12 w-10 md:w-12"
              src="https://www.theshubhamgroup.com/static/media/icon_whatsapp.417ad2d4d35da4649b19.png"
              alt="whatsapp--v1"
            />
          </a>
        </div>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/apartment' element={<Apartment />} />
            <Route path='/duplexvilla' element={<Duplexvilla />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/amenities' element={<Amenities />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/landing' element={<Landing />} />
            <Route path='/blogs' element={<Blogs />} />
            <Route path="/blog/chail-best-place-to-invest-in-himachal-pradesh" element={<Blog1 />} />
            <Route path='*' element={<Notfound />} />
          </Routes>
        <Footer />
      </div>

      {/* Loader */}
      {/* {loading && <Loader setLoading={setLoading} />} */}
    </main>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}
