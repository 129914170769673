import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const menuItems = [
    { id: 1, label: "HOME", href: "/" },
    { id: 2, label: "ABOUT", href: "/about" },
    { id: 3, label: "GALLERY", href: "/gallery" },
    { id: 4, label: "AMENITIES", href: "/amenities" },
    { id: 5, label: "CONTACT", href: "/contact" }
  ];

  const menuVariants = {
    closed: {
      clipPath: 'circle(0% at calc(100% - 3rem) 3rem)',
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
        staggerChildren: 0.1,
        staggerDirection: -1
      }
    },
    open: {
      clipPath: 'circle(150% at calc(100% - 3rem) 3rem)',
      transition: {
        type: "spring",
        stiffness: 70,
        damping: 20,
        staggerChildren: 0.1,
        delayChildren: 0.2,
      }
    }
  };

  const itemVariants = {
    closed: {
      y: 20,
      opacity: 0,
      transition: { duration: 0.2 }
    },
    open: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.4 }
    }
  };

  // Add useEffect to handle body scroll
  useEffect(() => {
    if (isMenuOpen) {
      // Prevent scrolling on the background when lightbox is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling when lightbox is closed
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to ensure we re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  return (
    <>
      <nav className="fixed top-0 left-0 w-full  z-50">
          <div className="flex items-center justify-between  px-8 md:pr-16 lg:pr-24 h-[120px] bg-[#fffcef]/95 backdrop-blur-md border-b border-neutral-200/50">
            {/* Logo */}
            <a href="/">
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                className="relative"
              >
                <img
                  src="https://d2r3u9c7j9d9om.cloudfront.net/mainlogobold.png"
                  alt="Logo"
                  className="h-[150px] mt-3 ml-8"
                />
              </motion.div>
            </a>

            {/* Desktop Menu */}
            <div className="hidden lg:flex items-center space-x-12">
              {menuItems.map((item) => (
                <motion.a
                  key={item.id}
                  href={item.href}
                  className="relative text-[#ad8a56]/80 hover:text-[#ad8a56] text-sm tracking-[0.2em] font-bold    "
                  whileHover={{ y: -2 }}

                >
                  <span>{item.label}</span>
                  <motion.div
                    className="absolute left-0 bottom-0 w-0 h-[1px] bg-neutral-400 group-hover:w-full"
                    whileHover={{ width: '100%' }}
                    transition={{ duration: 0.3 }}
                  />
                </motion.a>
              ))}
            </div>

            {/* Menu Button */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="lg:hidden relative z-50 w-12 h-12 rounded-full flex items-center justify-center"
            >
              <div className="relative w-6 h-6">
                <motion.span
                  animate={isMenuOpen ? { rotate: 45, y: 8 } : { rotate: 0, y: 0 }}
                  className="absolute inset-x-0 top-0 h-[3px] bg-[#ad8a56]"
                />
                <motion.span
                  animate={isMenuOpen ? { opacity: 0 } : { opacity: 1 }}
                  className="absolute inset-x-0 top-1/2 -translate-y-1/2 h-[3px] bg-[#ad8a56]"
                />
                <motion.span
                  animate={isMenuOpen ? { rotate: -45, y: -8 } : { rotate: 0, y: 0 }}
                  className="absolute inset-x-0 bottom-0 h-[3px] bg-[#ad8a56]"
                />
              </div>
            </motion.button>
          </div>
      </nav>

      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            variants={menuVariants}
            initial="closed"
            animate="open"
            exit="closed"
            onClick={() => { setIsMenuOpen(false) }}
            className="lg:hidden mt-[100px] fixed inset-0 z-40 bg-white/95 backdrop-blur-lg flex items-center justify-center"
          >
            <div className="relative w-full max-w-lg mx-auto px-8">

              {/* Mobile Menu Items */}
              <div className="relative flex flex-col items-center gap-8">
                {menuItems.map((item) => (
                  <motion.div
                    key={item.id}
                    variants={itemVariants}
                    className="relative group"
                  >
                    <motion.a
                      href={item.href}
                      onClick={() => setIsMenuOpen(false)}
                      className="relative block py-2"
                    >
                      <span className="text-2xl font-semibold tracking-[0.2em] 
                        group-hover:text-[#ad8a56] text-[#ad8a56]/70 transition-colors duration-300"
                      >
                        {item.label}
                      </span>
                      <motion.div
                        className="absolute left-0 bottom-0 w-0 h-[2px] bg-[#ad8a56] group-hover:w-full 
                          transition-all duration-300"
                      />
                    </motion.a>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar; 