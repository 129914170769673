import React from 'react'
import "../styles/loading.css"

function Loading720() {
    return (
        <div className="loader relative">
            <div className='absolute z-50 flex w-full h-full justify-center items-center'>
                <p className='text-xs text-white yeseva'>720P</p>
            </div>
        </div>
  )
}

export default Loading720