import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import line from '../line/line.svg';
import { Helmet } from 'react-helmet';

const Amenities = () => {
  const { pathname } = useLocation();
  const isAmenities = pathname === '/amenities';

  const amenities = [
    {
      id: 1,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/1.png',
      name: 'Clubhouse',
      description: 'Modern clubhouse with state-of-the-art facilities',
      color: 'bg-white/5'
    },
    {
      id: 2,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/2.png',
      name: 'Restaurant',
      description: 'Fine dining experience with diverse cuisine',
      color: 'bg-white/5'
    },
    {
      id: 3,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/3.png',
      name: 'Kids Area',
      description: 'Safe and fun playground for children',
      color: 'bg-white/5'
    },
    {
      id: 4,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/4.png',
      name: 'Parkade',
      description: 'Secure parking facility',
      color: 'bg-white/5'
    },
    {
      id: 5,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/5.png',
      name: 'Walking Trails',
      description: 'Scenic paths for your daily walks',
      color: 'bg-white/5'
    },
    {
      id: 6,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/6.png',
      name: 'Gymnasium',
      description: 'Fully equipped modern gym',
      color: 'bg-white/5'
    },
    {
      id: 7,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/7.png',
      name: 'Swimming Pool',
      description: 'Temperature controlled pool',
      color: 'bg-white/5'
    },
    {
      id: 8,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/8.png',
      name: 'Library',
      description: 'Peaceful reading environment',
      color: 'bg-white/5'
    },
    {
      id: 9,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/9.png',
      name: 'Celebration Area',
      description: 'Perfect space for your special moments',
      color: 'bg-white/5'
    },
    {
      id: 10,
      image: 'https://d2r3u9c7j9d9om.cloudfront.net/amenities_icon/icons_main/10.png',
      name: 'Banquet',
      description: 'Elegant banquet hall for events',
      color: 'bg-white/5'
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <section className={` ${isAmenities ? 'pt-[150px] mb-20' : ''} mb-10 bg-transparent`}>
      {/* Helmet for SEO */}
      {isAmenities && 
      <Helmet>
        <title>Amenities in Chail Heights Villas</title>
        <meta
          name="description"
          content="Explore the premium amenities at our luxury cottages and villas. Enjoy modern facilities, stunning views, and the perfect getaway in the hills."
        />
      </Helmet>}

      <div className="max-w-7xl  mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.8 }}
          className="text-center mb-8 flex flex-col items-center justify-center"
        >
          <h2 className="text-4xl md:text-5xl font-bold text-[#ad9b73] font-serif">
            Amenities
          </h2>
          {/* Animated decorative lines */}
        

      <img src={line} alt="line" className="w-full h-[50px] opacity-70 ml-[-5px]" />

        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6"
        >
          {amenities.map((amenity) => (
            <motion.div
              key={amenity.id}
              variants={itemVariants}
              className="relative group p-2 cursor-pointer rounded-2xl"
            >
              <div
              >
                <div className=" hover:scale-110 transform transition-transform duration-300">
                  <div className="w-16 h-16 mx-auto">
                    <img 
                      src={amenity.image} 
                      alt={amenity.name}
                      className="w-full h-full object-contain "
                    />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900 text-center">
                    {amenity.name}
                  </h3>
                  <p className="text-xs text-gray-600 leading-relaxed text-center">
                    {amenity.description}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Amenities;